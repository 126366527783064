import { Table } from 'antd';
import React from 'react';

function MemberProjectList({ list = [] }) {
  const columns = [
    {
      title: '프로젝트 명',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: '진행 상태',
      dataIndex: 'isComplete',
      key: 'isComplete',
      render: (_, row) => {
        const check = row['isComplete'];
        return (
          <span style={{ color: check ? 'grey' : 'purple' }}>
            {' '}
            {check ? '완료' : '진행중'}
          </span>
        );
      },
    },
    {
      title: '시작 일자',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: '종료 일자',
      dataIndex: 'end_date',
      key: 'end_date',
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={list}></Table>
    </>
  );
}

export default MemberProjectList;
