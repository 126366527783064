import MemberDashboard from '../../components/member/MemberDashboard';
import MemberLayout from './MemberLayout';

const MemberHome = () => {

  return (
    <MemberLayout MemberContent={MemberDashboard}/>
  );
};
export default MemberHome;


