import React from 'react';

function DivSection({
  stationNm = '',
  stationEn = '',
  descList = [],
  imgUrl = '',
  shops = [],
}) {
  // console.log(`[${stationNm}]`, shops.length);
  return (
    <section id="about" className="s-about target-section">
      <div className="row s-about__content width-sixteen-col">
        <div className="column grid-block grid-section-split">
          <img
            src="images/geometric_shape.svg"
            alt=""
            className="s-about__content-imgbg"
          />
          <div className="section-header grid-section-split__header">
            <div className="text-pretitle">{stationEn}</div>
            <h2 className="text-display-title">{stationNm}역</h2>
            <img src={imgUrl} alt={stationNm} />
          </div>{' '}
          {/*<!--end section-header -->*/}
          <div className="s-about__content-main grid-section-split__primary">
            <p className="attention-getter">
              <ul>
                {descList.map((item, idx) => (
                  <li key={`${idx}_${stationNm}`}>{item}</li>
                ))}
              </ul>
            </p>
          </div>{' '}
          {/*<!--end s-about__content-btn -->*/}
        </div>{' '}
        <p className="attention-getter">
          역데이터 출처) 용인 경전철 홈페이지{' '}
          <a
            href={'https://ever-line.co.kr/'}
            target={'_blank'}
            rel="noreferrer"
          >
            바로가기
          </a>{' '}
        </p>
        {/*<!--content-inner -->*/}
      </div>
      <hr />
      <div className="row s-about__content width-sixteen-col">
        <p>
          <div className="attention-getter">등록음식점 ({shops.length})개</div>
        </p>
      </div>
      <div className="row s-about__content width-sixteen-col">
        <div>
          <table className="shop-css">
            <thead>
              <tr>
                <th>음식점명</th>
                <th>주소</th>
              </tr>
            </thead>
            <tbody>
              {shops.map((item) => (
                <tr>
                  <td
                    key={item['objectId']}
                    dangerouslySetInnerHTML={{ __html: item['title'] }}
                  ></td>
                  <td>{item['roadAddress']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default DivSection;
