import Home from './screen/Home';
import './css/styles.css';
import { useCallback, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import About from './screen/About';
import Contact from './screen/Contact';
import Service from './screen/Service';
import Blog from './screen/Blog';
import MemberHome from './screen/member/MemberHome';
import OauthService from './screen/Login/OauthService';
import MemberProduct from './screen/member/MemberProduct';
import Privacy from './screen/Privacy';
import FoodEverline from './screen/apps/FoodEverline';

function App() {
  useEffect(() => {}, []);

  const initKakao = useCallback(() => {
    const jsKey = '8d0ab6148a4ebaa9f01508078ff8d893'; //process.env.REACT_APP_KAKAO_JS_KEY;
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(jsKey);
      console.log(window.Kakao.isInitialized());
    }
  }, []);

  const hamberger = useCallback(() => {
    const toggleButton = document.querySelector('.s-header__menu-toggle');
    const mainNavWrap = document.querySelector('.s-header__nav');
    const siteBody = document.querySelector('body');

    if (!(toggleButton && mainNavWrap)) return;

    toggleButton.addEventListener('click', function (e) {
      e.preventDefault();
      toggleButton.classList.toggle('is-clicked');
      siteBody.classList.toggle('menu-is-open');
    });

    mainNavWrap.querySelectorAll('.s-header__nav a').forEach(function (link) {
      link.addEventListener('click', function (event) {
        // at 900px and below
        if (window.matchMedia('(max-width: 900px)').matches) {
          toggleButton.classList.toggle('is-clicked');
          siteBody.classList.toggle('menu-is-open');
        }
      });
    });

    window.addEventListener('resize', function () {
      // above 900px
      if (window.matchMedia('(min-width: 901px)').matches) {
        if (siteBody.classList.contains('menu-is-open'))
          siteBody.classList.remove('menu-is-open');
        if (toggleButton.classList.contains('is-clicked'))
          toggleButton.classList.remove('is-clicked');
      }
    });
  }, []);

  useEffect(() => {
    hamberger();
  }, [hamberger]);

  useEffect(() => {
    initKakao();
  }, [initKakao]);

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'/contact'} element={<Contact />} />
        <Route path={'/services'} element={<Service />} />
        <Route path={'/blog'} element={<Blog />} />
        <Route path={'/oauth'} element={<OauthService />} />
        <Route path={'/member_home'} element={<MemberHome />} />
        <Route path={'/member_product'} element={<MemberProduct />} />
        <Route path={'/privacy'} element={<Privacy />} />
        <Route path={'/food_everline'} element={<FoodEverline />} />
      </Routes>
    </div>
  );
}

export default App;
