import MemberLayout from './MemberLayout';


function Product() {
  return (
    <>
        Product Ele
    </>
  )
}

function MemberProduct() {
  return (
    <MemberLayout MemberContent={Product}/>
  )
}

export default MemberProduct;