import { createSlice } from '@reduxjs/toolkit';
import { getSummary } from '../../apis/member/member_api';

export const summary = createSlice({
  name: 'summary',
  list: [],
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummary.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getSummary.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.list = payload;
      })
      .addCase(getSummary.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      });
  },
});

export default summary.reducer;
