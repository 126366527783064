import Axios from 'axios';

const appId = process.env.REACT_APP_PARSE_APP_ID_FOODEVERLINE;
const restKey = process.env.REACT_APP_PARSE_APP_REST_API_KEY_FOODEVERLINE;
const host = process.env.REACT_APP_PARSE_HOST_URL;

const header = {
  'X-Parse-Application-Id': appId,
  'X-Parse-REST-API-Key': restKey,
};
export async function getStations() {
  const url = `${host}/parse/classes/StationInfo`;
  let res = await Axios.get(url, {
    headers: header,
  });
  if (res.status === 200) {
    let rtn = res.data.results;
    // console.log(`[appId is]`, rtn);
    return rtn;
  }
  return [];
}

export async function getRestaruantMaster(stationCd) {
  const url = `${host}/parse/classes/RestaurantMaster`;
  let res = await Axios.get(url, {
    headers: header,
    params: { where: { station_cd: stationCd } },
  });
  if (res.status === 200) {
    let rtn = res.data.results;
    // console.log(`[restaraunt is]`, rtn);
    return rtn;
  }
  return [];
}
