import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, getSummary } from '../../store/apis/member/member_api';
import { Button } from 'antd';
import MemberProjectList from './MemberProjectList';
import MemberSummaryList from './MemberSummaryList';

function MemberDashboard() {
  const state = useSelector((state) => {
    return state;
  });
  const dispatch = useDispatch();

  const handleGetProjects = useCallback(() => {
    dispatch(getProjects());
    dispatch(getSummary());
    console.log('[state] ', state);
  }, [dispatch, state]);

  return (
    <>
      Dashboard
      <Button onClick={handleGetProjects}>Get</Button>
      <br />
      <MemberProjectList
        list={state && state['member'] && state['member']['project']}
      />
      <hr />
      <MemberSummaryList
        list={state && state['summary'] && state['summary']['list']}
      />
    </>
  );
}

export default MemberDashboard;
