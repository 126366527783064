import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
function About() {
  return (
    <>
      {/*<!-- preloader
    ================================================== -->*/}
      <div id="preloader">
        <div id="loader" className="dots-fade">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/*<!-- page wrap
    ================================================== -->*/}
      <div id="page" className="s-pagewrap">
        {/*<!-- # site header 
        ================================================== -->*/}
        <Header />
        {/*<!-- end s-header -->*/}
        {/*<!-- # site main content
        ================================================== -->*/}
        <section id="content" className="s-content">
          <section className="s-pageheader pageheader">
            <div className="row">
              <div className="column xl-12">
                <h1 className="page-title">
                  <span className="page-title__small-type text-pretitle">
                    About
                  </span>
                  Hi, I'm Monica
                </h1>
              </div>
            </div>
          </section>{' '}
          {/*<!-- end pageheader -->*/}
          <section className="s-pagecontent pagecontent">
            <div className="row pageintro">
              <div className="column xl-6 lg-12">
                <h2 className="text-display-title">
                  Some inspiring words to describe yourself
                </h2>
              </div>
              <div className="column xl-6 lg-12 u-flexitem-x-right">
                <p className="lead">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Alias eos quas blanditiis, quos sint nostrum fugit aperiam
                  inventore optio itaque molestias corporis, ipsa tenetur
                  eligendi nihil iste porro, natus culpa consequuntur? Maxime,
                  corporis tempore. Sed tenetur veritatis velit recusandae eum,
                  molestiae voluptate ducimus laudantium esse illo doloribus
                  atque eligendi deleniti iusto.
                </p>
              </div>
            </div>{' '}
            {/*<!-- end pageintro -->*/}
            <div className="row pagemedia">
              <d className="column xl-12">
                <figure className="page-media">
                  <img
                    src="images/thumbs/about/about-1200.jpg"
                    srcSet="images/thumbs/about/about-2400.jpg 2400w, 
                                             images/thumbs/about/about-1200.jpg 1200w, 
                                             images/thumbs/about/about-600.jpg 600w"
                    sizes="(max-width: 2400px) 100vw, 2400px"
                    alt=""
                  />
                </figure>
              </d>
            </div>{' '}
            {/*<!-- end pagemedia -->*/}
            <div className="row width-narrower pagemain">
              <div className="column xl-12">
                <h2>How I Got Here</h2>
                <p>
                  Eaque temporibus culpa perferendis expedita assumenda mollitia
                  magnam. Lorem ipsum dolor sit amet consectetur adipisicing
                  elit facilis voluptates voluptatum animi numquam quas ea
                  praesentium quaerat maxime sunt odit inventore itaque est et
                  autem sequi nulla. Distinctio obcaecati nesciunt asperiores
                  dolorum tenetur voluptates, nemo alias doloremque. Quos cumque
                  ipsum laudantium odio vero aut odit nostrum aliquam? Nostrum
                  in facilis, minus fuga quasi voluptas explicabo possimus
                  incidunt, expedita tempora eius rem nobis sequi. Doloribus
                  esse sint suscipit quam nisi blanditiis voluptate explicabo.
                </p>
                <h2 className="u-add-bottom">My Values & Beliefs</h2>
                <div className="grid-list-items list-items">
                  <div className="grid-list-items__item list-items__item u-remove-bottom">
                    <div className="list-items__item-header">
                      <h6 className="list-items__item-small-title">
                        Consectetur
                      </h6>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Mollitia accusamus consectetur adipisicing elit excepturi
                      corrupti nam quae exercitationem cupiditate, provident
                      ipsa delectus vero possimus reprehenderit quas ut officiis
                      tempora voluptatum quibusdam consectetur commodi.
                    </p>
                  </div>
                  <div className="grid-list-items__item list-items__item u-remove-bottom">
                    <div className="list-items__item-header">
                      <h6 className="list-items__item-small-title">
                        Adipisicing
                      </h6>
                    </div>
                    <p>
                      Molestias, autem impedit culpa dolores excepturi, quidem
                      unde ducimus, rerum commodi deserunt earum, minus
                      voluptatum. Lorem ipsum dolor sit amet consectetur,
                      adipisicing elit. Saepe doloremque provident quas quae
                      exercitationem laboriosam.
                    </p>
                  </div>
                  <div className="grid-list-items__item list-items__item u-remove-bottom">
                    <div className="list-items__item-header">
                      <h6 className="list-items__item-small-title">
                        Doloremque
                      </h6>
                    </div>
                    <p>
                      Mollitia accusamus consectetur. Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Adipisicing elit excepturi
                      corrupti nam quae exercitationem cupiditate, provident
                      ipsa delectus vero possimus reprehenderit quas ut officiis
                      tempora voluptatum quibusdam consectetur commodi!
                    </p>
                  </div>
                  <div className="grid-list-items__item list-items__item u-remove-bottom">
                    <div className="list-items__item-header">
                      <h6 className="list-items__item-small-title">
                        Assumenda
                      </h6>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Saepe doloremque provident quas quae exercitationem
                      laboriosam. Molestias, autem? Impedit culpa dolores
                      excepturi, quidem unde ducimus, rerum commodi deserunt
                      earum, minus voluptatum?
                    </p>
                  </div>
                </div>{' '}
                {/*<!--grid-list-items -->*/}
                <h2>Why Work With Me</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
                  temporibus culpa perferendis expedita assumenda mollitia
                  magnam, facilis voluptates voluptatum animi numquam quas ea
                  praesentium quaerat maxime sunt odit inventore itaque est et
                  autem sequi nulla. Distinctio obcaecati nesciunt asperiores
                  dolorum tenetur voluptates, nemo alias doloremque. Quos cumque
                  ipsum laudantium odio vero aut odit nostrum aliquam? Nostrum
                  in facilis, minus fuga quasi voluptas explicabo possimus
                  incidunt, expedita tempora eius rem nobis sequi. Doloribus
                  esse sint suscipit quam nisi blanditiis voluptate explicabo.
                </p>
                <p>
                  Quibusdam quis autem voluptatibus earum vel ex error ea magni.
                  Rerum quam quos. Aut asperiores sit mollitia. Rem neque et
                  voluptatem eos quia sed eligendi et. Eaque velit eligendi ut
                  magnam. Cumque ducimus laborum doloribus facere maxime vel
                  earum quidem enim suscipit. Numquam, corporis accusamus
                  commodi saepe nostrum nesciunt nisi rem necessitatibus unde
                  laboriosam molestias, quam ipsa voluptatum sed ex cumque
                  facilis assumenda maiores tempore reiciendis enim accusantium?
                </p>
                <h2>A Few More Words About Myself</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  assumenda debitis aut quidem ad voluptates quibusdam soluta
                  temporibus, eveniet nobis tempore sunt, provident sapiente
                  accusamus dignissimos voluptatum fugiat nulla dicta nihil
                  molestiae aspernatur error in. Ut quo porro voluptatum
                  delectus asperiores culpa non cum facere minima nesciunt
                  assumenda, praesentium reprehenderit accusamus, quam repellat
                  nobis nemo? Ut magnam unde culpa pariatur possimus sunt fugiat
                  ea maxime praesentium eaque quo odio sed eligendi harum dolor,
                  repellendus reprehenderit facere nihil quam. Saepe, magni.
                </p>
              </div>{' '}
              {/*<!-- end grid-block-->*/}
            </div>{' '}
            {/*<!-- end pagemain -->*/}
          </section>{' '}
          {/*<!-- pagecontent -->*/}
          <section className="s-testimonials">
            <div className="s-testimonials__header row row-x-center text-center">
              <div className="column xl-8 lg-12">
                <p className="text-pretitle">Testimonials</p>
                <h3>Reviews From Real Clients</h3>
              </div>
            </div>{' '}
            {/*<!--end s-testimonials__header -->*/}
            <div className="row s-testimonials__content">
              <div className="column xl-12 testimonials">
                <div className="swiper-container testimonials__slider page-slider">
                  <div className="swiper-wrapper">
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Molestiae incidunt consequatur quis ipsa autem nam sit
                        enim magni. Voluptas tempore rem. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-01.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>John Rockefeller</strong>
                          <span>Standard Oil Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Voluptas tempore rem. Molestiae incidunt consequatur
                        quis ipsa autem nam sit enim magni. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-04.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>Andrew Carnegie</strong>
                          <span>Carnegie Steel Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Nisi dolores quaerat fuga rem nihil nostrum. Molestiae
                        incidunt consequatur quis ipsa autem nam sit enim magni.
                        Voluptas tempore rem. Explicabo a quaerat sint autem
                        dolore ducimus ut consequatur neque. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-06.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>Henry Ford</strong>
                          <span>Ford Motor Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Molestiae incidunt consequatur quis ipsa autem nam sit
                        enim magni. Voluptas tempore rem. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-02.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>John Morgan</strong>
                          <span>JP Morgan & Co.</span>
                        </cite>
                      </div>
                    </div>
                  </div>{' '}
                  {/*<!-- end swiper-wrapper -->*/}
                  <div className="swiper-pagination"></div>
                </div>{' '}
                {/*<!--end testimonials__slider -->*/}
              </div>{' '}
              {/*<!-- testimonials -->*/}
            </div>{' '}
            {/*<!--end s-testimonials__content -->*/}
          </section>{' '}
          {/*<!-- end s-testimonials -->*/}
        </section>{' '}
        {/*<!-- s-content-->*/}
        {/*<!-- # cta
        ================================================== -->*/}
        <section id="cta" className="s-cta">
          <div className="row row-x-center text-center">
            <div className="column xl-8 lg-12">
              <div className="s-cta__content">
                <h2 className="text-display-title">
                  Get started with a consultation today.
                </h2>
                <p className="lead">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis
                  rem, esse doloribus sint eaque at debitis enim vitae minus
                  expedita ratione dignissimos sit nostrum optio sequi. Ipsa at
                  beatae quam.
                </p>
                <a href="/contact" className="btn btn--primary">
                  Login
                </a>
              </div>
            </div>
          </div>
        </section>{' '}
        {/*<!-- end s-cta -->*/}
        {/*<!-- # footer 
        ================================================== -->*/}
        <Footer />
        {/*<!-- end s-footer -->*/}
      </div>{' '}
      {/*<!-- end page-wrap -->*/}
    </>
  );
}

export default About;
