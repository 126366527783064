import { createAsyncThunk } from '@reduxjs/toolkit';

import Parse from 'parse/dist/parse.min';
import { getCurrentUser } from '../common/common_parse_api';

// const HOST_URI = process.env.REACT_APP_PARSE_HOST_URL;

export const getProjects = createAsyncThunk(
  'GET/GET_PROJECTS',
  async (arg, thunkAPI) => {
    const className = 'UserProject';
    const cuser = await getCurrentUser();
    const username = cuser.get('username');
    const userClassName = `${className}_${username}`;

    // const url = `${HOST_URI}/${userClassName}`;
    // console.log("[api call] ", url, username);

    try {
      // const user =  Parse.User.current();
      const userProject = Parse.Object.extend(userClassName);
      const query = new Parse.Query(userProject);
      const res = await query.find();
      const mres = res.map((item) => {
        return {
          key: item['id'],
          project_name: item.get('project_name'),
          isComplete: item.get('isComplete'),
          start_date: item.get('start_date'),
          end_date: item.get('end_date'),
        };
      });
      // console.log('[res] ', res, mres);
      return thunkAPI.fulfillWithValue(mres);
    } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue({
        errorMessage: '프로젝트 조회 실패',
      });
    }
  },
);

export const getSummary = createAsyncThunk(
  'GET/GET_SUMMARY',
  async (arg, thunkAPI) => {
    try {
      const className = 'DailySummary';
      const cuser = await getCurrentUser();
      const po = Parse.Object.extend(className);
      const query = new Parse.Query(po);
      query.equalTo('user', cuser);
      query.descending('date');
      const res = await query.find();
      const mres = res.map((item) => {
        return {
          key: item.id,
          date: item.get('date'),
          count: item.get('count'),
          rate: item.get('rate'),
          sum: item.get('sum'),
        };
      });

      return thunkAPI.fulfillWithValue(mres);
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue({ err });
    }
  },
);
