import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="s-header">
      <div className="row s-header__inner width-sixteen-col">
        <div className="s-header__block">
          <div className="s-header__logo">
            <Link className="logo" to="/">
              <img src="images/blue2.svg" alt="Homepage" />
            </Link>
          </div>

          {/* <a className="s-header__menu-toggle" href="#0">
            <span>Menu</span>
          </a> */}
        </div>{' '}
        {/*<!--end s-header__block -->*/}
        {/* <nav className="s-header__nav">
          <ul className="s-header__menu-links">
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/blog">Journal</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>{' '}
          <div className="s-header__contact">
          </div>{' '}
        </nav>{' '} */}
        {/*<!--end s-header__nav -->*/}
      </div>{' '}
      {/*<!--end s-header__inner -->*/}
    </header>
  );
}

export default Header;
