import { configureStore } from '@reduxjs/toolkit';

import { member } from './reducers/member/member_reducer';
import { summary } from './reducers/member/summary_reducer';

export const { getProjects } = member.actions;
export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    member: member.reducer,
    summary: summary.reducer,
  },
});
