import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

function Service() {
  return (
    <>
      {/*<!-- preloader
        ================================================== --> */}
      <div id="preloader">
        <div id="loader" className="dots-fade">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/*<!-- page wrap
        ================================================== --> */}
      <div id="page" className="s-pagewrap">
        {/*<!-- # site header 
            ================================================== --> */}
        <Header /> {/*<!-- end s-header --> */}
        {/*<!-- # site main content
            ================================================== --> */}
        <section id="content" className="s-content">
          <section className="s-pageheader pageheader">
            <div className="row">
              <div className="column xl-12">
                <h1 className="page-title">
                  <span className="page-title__small-type text-pretitle">
                    Services
                  </span>
                  High-Impact Services
                </h1>
              </div>
            </div>
          </section>{' '}
          {/*<!-- pageheader --> */}
          <section className="s-pagecontent pagecontent">
            <div className="row pageintro">
              <div className="column xl-6 lg-12">
                <h2 className="text-display-title">
                  High-impact services to help your business
                </h2>
              </div>
              <div className="column xl-6 lg-12 u-flexitem-x-right">
                <p className="lead">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Alias eos quas blanditiis, quos sint nostrum fugit aperiam
                  inventore optio itaque molestias corporis, ipsa tenetur
                  eligendi nihil iste porro, natus culpa consequuntur? Maxime,
                  corporis tempore. Sed tenetur veritatis velit recusandae eum,
                  molestiae voluptate ducimus laudantium esse illo doloribus
                  atque eligendi deleniti iusto.
                </p>
              </div>
            </div>{' '}
            {/*<!-- pageintro --> */}
            <div className="row">
              <div className="column xl-12 grid-block">
                <div className="grid-full grid-list-items list-items show-ctr">
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Digital Marketing
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Rerum quam quos. Aut asperiores sit mollitia. Rem neque
                        et voluptatem eos quia sed eligendi et. Eaque velit
                        eligendi ut magnam. Cumque ducimus laborum doloribus
                        facere maxime vel earum quidem enim suscipit.
                      </p>

                      <ul className="list-services">
                        <li>Cumque Ducimus</li>
                        <li>Maxime Vel</li>
                        <li>Eligendi Et</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Social Media Marketing
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam.
                      </p>

                      <ul className="list-services">
                        <li>Lorem Ipsum</li>
                        <li>voluptatibus Earum</li>
                        <li>Cumque Ducimus</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Content Marketing
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Rerum quam quos. Quibusdam quis autem voluptatibus earum
                        vel ex error ea magni. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam.
                      </p>

                      <ul className="list-services">
                        <li>Eaque velit</li>
                        <li>Asperiores</li>
                        <li>Cumque Ducimus</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Paid Advertising
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Eaque velit eligendi ut magnam.Rerum quam quos. Aut
                        asperiores sit mollitia. Rem neque et voluptatem eos
                        quia sed eligendi et. Cumque ducimus laborum doloribus
                        facere maxime vel earum quidem enim suscipit.
                      </p>

                      <ul className="list-services">
                        <li>Cumque Ducimus</li>
                        <li>Maxime Vel</li>
                        <li>Eligendi Et</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Search Engine Optimization
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Aut asperiores sit mollitia. Rem neque et voluptatem eos
                        quia sed eligendi et. Eaque velit eligendi ut magnam.
                        Cumque ducimus laborum doloribus facere maxime vel earum
                        quidem enim suscipit.
                      </p>

                      <ul className="list-services">
                        <li>Cumque Ducimus</li>
                        <li>Maxime Vel</li>
                        <li>Eligendi Et</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Advance Analytics
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam.
                      </p>

                      <ul className="list-services">
                        <li>Lorem Ipsum</li>
                        <li>Voluptatibus Earum</li>
                        <li>Cumque Ducimus</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Email Marketing
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Rerum quam quos. Aut asperiores sit mollitia. Rem neque
                        et voluptatem eos quia sed eligendi et. Eaque velit
                        eligendi ut magnam. Cumque ducimus laborum doloribus
                        facere maxime vel earum quidem enim suscipit.
                      </p>

                      <ul className="list-services">
                        <li>Doloribus</li>
                        <li>Mollitia</li>
                        <li>Eligendi Et</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">
                        Conversion Rate Optimization
                      </h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam.
                      </p>

                      <ul className="list-services">
                        <li>Cumque Ducimus</li>
                        <li>Maxime Vel</li>
                        <li>Eligendi Et</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                  <div className="grid-list-items__item list-items__item">
                    <div className="list-items__item-header">
                      <h3 className="list-items__item-title">Web Design</h3>
                    </div>
                    <div className="list-items__item-text">
                      <p>
                        Aut asperiores sit mollitia. Rem neque et voluptatem eos
                        quia sed eligendi et. Eaque velit eligendi ut magnam.
                        Cumque ducimus laborum doloribus facere maxime vel earum
                        quidem enim suscipit.
                      </p>

                      <ul className="list-services">
                        <li>Suscipit</li>
                        <li>Voluptatem</li>
                        <li>Eligendi Et</li>
                      </ul>
                    </div>
                  </div>{' '}
                  {/*<!-- end list-items__item --> */}
                </div>{' '}
                {/*<!-- grid-list-items --> */}
              </div>{' '}
              {/*<!-- end grid-block--> */}
            </div>{' '}
            {/*<!-- end row --> */}
          </section>{' '}
          {/*<!-- pagecontent --> */}
          <section className="s-testimonials">
            <div className="s-testimonials__header row row-x-center text-center">
              <div className="column xl-8 lg-12">
                <p className="text-pretitle">Testimonials</p>
                <h3>Reviews From Real Clients</h3>
              </div>
            </div>{' '}
            {/*<!--end s-testimonials__header --> */}
            <div className="row s-testimonials__content">
              <div className="column xl-12 testimonials">
                <div className="swiper-container testimonials__slider page-slider">
                  <div className="swiper-wrapper">
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Molestiae incidunt consequatur quis ipsa autem nam sit
                        enim magni. Voluptas tempore rem. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-01.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>John Rockefeller</strong>
                          <span>Standard Oil Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Voluptas tempore rem. Molestiae incidunt consequatur
                        quis ipsa autem nam sit enim magni. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-04.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>Andrew Carnegie</strong>
                          <span>Carnegie Steel Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Nisi dolores quaerat fuga rem nihil nostrum. Molestiae
                        incidunt consequatur quis ipsa autem nam sit enim magni.
                        Voluptas tempore rem. Explicabo a quaerat sint autem
                        dolore ducimus ut consequatur neque. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-06.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>Henry Ford</strong>
                          <span>Ford Motor Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Molestiae incidunt consequatur quis ipsa autem nam sit
                        enim magni. Voluptas tempore rem. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-02.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>John Morgan</strong>
                          <span>JP Morgan & Co.</span>
                        </cite>
                      </div>
                    </div>
                  </div>{' '}
                  {/*<!-- end swiper-wrapper --> */}
                  <div className="swiper-pagination"></div>
                </div>{' '}
                {/*<!--end testimonials__slider --> */}
              </div>{' '}
              {/*<!-- testimonials --> */}
            </div>{' '}
            {/*<!--end s-testimonials__content --> */}
          </section>{' '}
          {/*<!-- end s-testimonials --> */}
        </section>{' '}
        {/*<!-- s-content--> */}
        {/*<!-- # cta
            ================================================== --> */}
        <section id="cta" className="s-cta">
          <div className="row row-x-center text-center">
            <div className="column xl-8 lg-12">
              <div className="s-cta__content">
                <h2 className="text-display-title">
                  Get started with a consultation today.
                </h2>
                <p className="lead">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis
                  rem, esse doloribus sint eaque at debitis enim vitae minus
                  expedita ratione dignissimos sit nostrum optio sequi. Ipsa at
                  beatae quam.
                </p>
                <a href="contact.html" className="btn btn--primary">
                  Let's Work Together
                </a>
              </div>
            </div>
          </div>
        </section>{' '}
        {/*<!-- end s-cta --> */}
        {/*<!-- # footer 
            ================================================== --> */}
        <Footer /> {/*<!-- end s-footer --> */}
      </div>{' '}
      {/*<!-- end page-wrap --> */}
    </>
  );
}

export default Service;
