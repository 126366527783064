import React, { useCallback, useState } from 'react';
import Parse from 'parse/dist/parse.min.js';
import { Button, Divider, Input } from 'antd';
import styled from 'styled-components';

function RegisterDiv() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const doUserRegistration = useCallback(async () => {
    const usernameValue = username;
    const passwordValue = password;
    try {
      // Since the signUp method returns a Promise, we need to call it using await
      const createdUser = await Parse.User.signUp(usernameValue, passwordValue);
      alert(
        `Success! User ${createdUser.getUsername()} was successfully created!`
      );
      setPassword('');
      setUsername('');
      return true;
    } catch (error) {
      // signUp can fail if any parameter is blank or failed an uniqueness check on the server
      alert(`Error! ${error}`);
      return false;
    }
  }, [password, username]);

  return (
    <>
      <LoDiv>
        <div className="container">
          <h2 className="heading">{'회원가입'}</h2>
          <Divider />
          <div className="form_wrapper">
            <Input
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Username"
              size="large"
              className="form_input"
            />
            <Input
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              size="large"
              type="password"
              className="form_input"
            />
          </div>
          <div className="form_buttons">
            <Button
              onClick={() => doUserRegistration()}
              type="primary"
              className="form_button"
              color={'#208AEC'}
              size="large"
            >
              Sign Up
            </Button>
          </div>
        </div>
      </LoDiv>
    </>
  );
}

export default RegisterDiv;

const LoDiv = styled.div`
  .container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 20px 0;
    text-align: left;
  }

  .header {
    align-items: center;
    padding: 25px 0;
    background-color: #208aec;
  }

  .header_logo {
    height: 55px;
    margin-bottom: 20px;
    object-fit: contain;
  }

  .header_text_bold {
    margin-bottom: 3px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    font-weight: bold;
  }

  .header_text {
    color: rgba(255, 255, 255, 0.9);
    font-size: 15px;
  }

  .heading {
    font-size: 22px;
  }

  .form_wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .form_input {
    margin-bottom: 20px;
  }

  .form_button {
    width: 100%;
  }
`;
