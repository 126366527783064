import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

function Blog() {
  return (
    <>
      {/*<!-- preloader
    ================================================== -->*/}
      <div id="preloader">
        <div id="loader" className="dots-fade">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/*<!-- page wrap
    ================================================== -->*/}
      <div id="page" className="s-pagewrap">
        {/*<!-- # site header
        ================================================== -->*/}
        <Header />
        {/*<!-- end s-header -->*/}
        {/*<!-- # site main content
        ================================================== -->*/}
        <section id="content" className="s-content">
          <section className="s-pageheader pageheader">
            <div className="row">
              <div className="column xl-12">
                <h1 className="page-title">
                  <span className="page-title__small-type text-pretitle">
                    My Blog
                  </span>
                  Recent Writings
                </h1>
              </div>
            </div>
          </section>

          <section className="s-pagecontent pagecontent">
            <div className="row">
              <div className="column xl-12 grid-block">
                {/*<!-- <div className="grid-full">         -->*/}
                <div className="grid-full grid-list-items">
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Sponsored</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="https://www.dreamhost.com/r.cgi?287326">
                          Need Web Hosting for Your Websites?
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Need hosting for your website? We would highly recommend{' '}
                        <a href="https://www.dreamhost.com/r.cgi?287326">
                          DreamHost
                        </a>
                        . Enjoy 100% in-house support, guaranteed performance
                        and uptime, 1-click installs, and a super-intuitive
                        control panel to make managing your websites and
                        projects easy.{' '}
                        <a href="https://www.dreamhost.com/r.cgi?287326">
                          Sign up
                        </a>{' '}
                        now to get started.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">SEO</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          7 SEO Factors to Consider for Better Website Ranking
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Web Design</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          5 Effective Web Design Principles
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Rerum ullam et laborum aperiam. Dignissimos quae
                        voluptatibus ullam esse sapiente fugiat, rerum labore
                        cupiditate obcaecati dolore necessitatibus id magni rem
                        officia dolor nam sit laudantium. Quo eaque eveniet
                        quibusdam unde.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Productivity</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          The Advantages of Hiring a Consultant Rather Than An
                          Employee Or An Agency
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Rerum quam quos. Quibusdam quis autem voluptatibus earum
                        vel ex error ea magni. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Productivity</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          5 Marketing Productivity Apps for Your Team
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Rerum quam quos. Quibusdam quis autem voluptatibus earum
                        vel ex error ea magni. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Marketing</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          How to Determine the Reach of Your Campaign
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">SEO</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          The Complete Guide to Redirects
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Eaque velit eligendi ut magnam.Quibusdam quis autem
                        voluptatibus earum vel ex error ea magni. Rerum quam
                        quos. Aut asperiores sit mollitia. Rem neque et
                        voluptatem eos quia sed eligendi et. Cumque ducimus
                        laborum doloribus facere maxime vel earum quidem enim
                        suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">SEO</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          What Backlinks Are & Why They Are Important for SEO
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Marketing</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          Why Is Data-Driven Marketing Important?
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Rerum quam quos. Quibusdam quis autem voluptatibus earum
                        vel ex error ea magni. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Marketing</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          How to Develop Successful Marketing Strategies
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia. Rem
                        neque et voluptatem eos quia sed eligendi et. Eaque
                        velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Keyword Research</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="single.html">
                          What Exactly Are Keywords? How to Make Use of Them for
                          SEO
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Rem neque et voluptatem eos quia sed eligendi et.
                        Quibusdam quis autem voluptatibus earum vel ex error ea
                        magni. Rerum quam quos. Aut asperiores sit mollitia.
                        Eaque velit eligendi ut magnam. Cumque ducimus laborum
                        doloribus facere maxime vel earum quidem enim suscipit.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog-card -->*/}
                  <div className="grid-list-items__item blog-card">
                    <div className="blog-card__header">
                      <div className="blog-card__cat-links">
                        <a href="blog.html">Sponsored</a>
                      </div>
                      <h3 className="blog-card__title">
                        <a href="https://www.dreamhost.com/r.cgi?287326">
                          Need Web Hosting for Your Websites?
                        </a>
                      </h3>
                    </div>
                    <div className="blog-card__text">
                      <p>
                        Need hosting for your website? We would highly recommend{' '}
                        <a href="https://www.dreamhost.com/r.cgi?287326">
                          DreamHost
                        </a>
                        . Enjoy 100% in-house support, guaranteed performance
                        and uptime, 1-click installs, and a super-intuitive
                        control panel to make managing your websites and
                        projects easy.{' '}
                        <a href="https://www.dreamhost.com/r.cgi?287326">
                          Sign up
                        </a>{' '}
                        now to get started.
                      </p>
                    </div>
                  </div>{' '}
                  {/*<!-- end blog card -->*/}
                </div>{' '}
                {/*<!-- grid-list-items -->*/}
                {/*<!-- </div>     -->*/}
              </div>{' '}
              {/*<!-- end grid-block-->*/}
            </div>{' '}
            {/*<!-- end row -->*/}
            {/*<!-- pagination -->*/}
            <div className="row navigation pagination">
              <div className="column xl-12">
                <nav className="pgn">
                  <ul>
                    <li>
                      <a className="pgn__prev" href="#0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.707 17.293L8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a className="pgn__num" href="#0">
                        1
                      </a>
                    </li>
                    <li>
                      <span className="pgn__num current">2</span>
                    </li>
                    <li>
                      <a className="pgn__num" href="#0">
                        3
                      </a>
                    </li>
                    <li>
                      <a className="pgn__num" href="#0">
                        4
                      </a>
                    </li>
                    <li>
                      <a className="pgn__num" href="#0">
                        5
                      </a>
                    </li>
                    <li>
                      <span className="pgn__num dots">…</span>
                    </li>
                    <li>
                      <a className="pgn__num" href="#0">
                        8
                      </a>
                    </li>
                    <li>
                      <a className="pgn__next" href="#0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>{' '}
              {/*<!-- end column -->*/}
            </div>{' '}
            {/*<!-- end pagination -->*/}
          </section>
        </section>{' '}
        {/*<!-- s-content-->*/}
        {/*<!-- # cta
        ================================================== -->*/}
        <section id="cta" className="s-cta">
          <div className="row row-x-center text-center">
            <div className="column xl-8 lg-12">
              <div className="s-cta__content">
                <h2 className="text-display-title">
                  Get started with a consultation today.
                </h2>
                <p className="lead">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis
                  rem, esse doloribus sint eaque at debitis enim vitae minus
                  expedita ratione dignissimos sit nostrum optio sequi. Ipsa at
                  beatae quam.
                </p>
                <a href="contact.html" className="btn btn--primary">
                  Let's Work Together
                </a>
              </div>
            </div>
          </div>
        </section>{' '}
        {/*<!-- end s-cta -->*/}
        {/*<!-- # footer
        ================================================== -->*/}
        <Footer />
        {/*<!-- end s-footer -->*/}
      </div>{' '}
      {/*<!-- end page-wrap -->*/}
    </>
  );
}

export default Blog;
