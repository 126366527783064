import { Table } from 'antd';
import { useMemo } from 'react';

function MemberSummaryList({ list = [] }) {
  const columns = useMemo(() => {
    return [
      {
        title: '일자',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: '일일 아이템 개수',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: '점수',
        dataIndex: 'rate',
        key: 'rate',
      },
      {
        title: '합계',
        dataIndex: 'sum',
        key: 'sum',
      },
    ];
  }, []);
  return (
    <>
      <Table columns={columns} dataSource={list} />
    </>
  );
}

export default MemberSummaryList;
