import { Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LoginDiv from './Login/LoginDiv';
import RegisterDiv from './Login/RegisterDiv';

function Contact() {


  const [isLogin, setIsLogin] = useState(true);
  const [loginUserInfo, setLoginUserInfo] = useState({});

  const handleChangeLogin = useCallback((checked) => {
    console.log('[checked]', checked);
    setIsLogin(checked);
  },[]);


  useEffect(()=>{
    console.log('[loginUserInfo]', loginUserInfo);
  },[loginUserInfo]);

  return (
    <>
      {/*<!-- preloader
    ================================================== -->*/}
      <div id="preloader">
        <div id="loader" className="dots-fade">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      {/*<!-- page wrap
    ================================================== -->*/}
      <div id="page" className="s-pagewrap">
        {/*<!-- # site header 
        ================================================== -->*/}
        <Header /> {/*<!-- end s-header -->*/}
        {/*<!-- # site main content
        ================================================== -->*/}
        <section id="content" className="s-content">
          <section className="s-pageheader pageheader">
            <div className="row">
              <div className="column xl-12">
                <h1 className="page-title">
                  <span className="page-title__small-type text-pretitle">
                    로그인을 하시면 더 많은 혜택을 누릴수 있습니다.
                  </span>
                  Contact Us
                </h1>
              </div>
            </div>
          </section>{' '}
          {/*<!--pageheader -->*/}
          <section className="s-pagecontent pagecontent">
            <div className="row pageintro">
              <div className="column xl-6 lg-12">
                <h2 className="text-display-title">Welcome!</h2>
              </div>
              <div className="column xl-6 lg-12 u-flexitem-x-right">
                <p className="lead">
                  회원이 되시면 핸드메이드 앱의 고객이 되시며, 고객님의 사업을
                  더욱 풍성하게 하기 위한 고민을 담아 세상에 단 하나뿐인 앱을
                  만드는 일이 시작됩니다.
                </p>
              </div>
            </div>{' '}

            

            <div className="row">
              <div className="column xl-12">
              회원가입 <Switch defaultChecked checked={isLogin} onChange={handleChangeLogin}/> 로그인
                {isLogin ? <LoginDiv setIsLogin={setIsLogin} setLoginUserInfo={setLoginUserInfo}/> : <RegisterDiv />}
              </div>
            </div>
            {/*<!-- pageintro -->*/}
            <div className="row pagemedia">
              <div className="column xl-12">
                <figure className="page-media">
                  <img
                    src="images/thumbs/contact/contact-1200.jpg"
                    srcSet="images/thumbs/contact/contact-2400.jpg 2400w, 
                                             images/thumbs/contact/contact-1200.jpg 1200w, 
                                             images/thumbs/contact/contact-600.jpg 600w"
                    sizes="(max-width: 2400px) 100vw, 2400px"
                    alt=""
                  />
                </figure>
              </div>
            </div>{' '}
            {/*<!-- pagemedia -->*/}
            <div className="row width-narrower pagemain">
              <div className="column xl-12">
                <h2>문의만 남기고 싶으신 고객님을 위한 선택</h2>

                <div className="row">
                  <div className="column xl-6 md-12 contact-cta">
                    <p>
                      문의만 남기셔도 됩니다. 핸드메이드 앱은 단 한명의 고객과 평생 같이 성장하는 서비스를 위해 노력합니다.
                      궁금한 사항이나 컨설팅 의뢰도 작거나 큰것 어느 고민이든 같이 고민하며 방법을 찾겠습니다.
                    </p>

                    <a
                      href="mailto:restnfeel@gmail.com"
                      className="btn btn--primary u-fullwidth contact-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        // style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"
                      >
                        <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path>
                      </svg>
                      핸드메이드 앱에게 메일보내기
                    </a>
                  </div>
                  <div className="column xl-5 md-12 u-flexitem-x-right">
                    <div className="contact-block">
                      <h6>Follow On Social</h6>
                      <ul className="contact-social social-list">
                        <li>
                          <a href="#0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              //   style="fill:rgba(0, 0, 0, 1);transform:;-ms-filter:"
                            >
                              <path d="M20,3H4C3.447,3,3,3.448,3,4v16c0,0.552,0.447,1,1,1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325,1.42-3.592,3.5-3.592 c0.699-0.002,1.399,0.034,2.095,0.107v2.42h-1.435c-1.128,0-1.348,0.538-1.348,1.325v1.735h2.697l-0.35,2.725h-2.348V21H20 c0.553,0,1-0.448,1-1V4C21,3.448,20.553,3,20,3z"></path>
                            </svg>
                            <span className="u-screen-reader-text">
                              Facebook
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              //   style="fill:rgba(0, 0, 0, 1);transform:;-ms-filter:"
                            >
                              <path d="M19.633,7.997c0.013,0.175,0.013,0.349,0.013,0.523c0,5.325-4.053,11.461-11.46,11.461c-2.282,0-4.402-0.661-6.186-1.809 c0.324,0.037,0.636,0.05,0.973,0.05c1.883,0,3.616-0.636,5.001-1.721c-1.771-0.037-3.255-1.197-3.767-2.793 c0.249,0.037,0.499,0.062,0.761,0.062c0.361,0,0.724-0.05,1.061-0.137c-1.847-0.374-3.23-1.995-3.23-3.953v-0.05 c0.537,0.299,1.16,0.486,1.82,0.511C3.534,9.419,2.823,8.184,2.823,6.787c0-0.748,0.199-1.434,0.548-2.032 c1.983,2.443,4.964,4.04,8.306,4.215c-0.062-0.3-0.1-0.611-0.1-0.923c0-2.22,1.796-4.028,4.028-4.028 c1.16,0,2.207,0.486,2.943,1.272c0.91-0.175,1.782-0.512,2.556-0.973c-0.299,0.935-0.936,1.721-1.771,2.22 c0.811-0.088,1.597-0.312,2.319-0.624C21.104,6.712,20.419,7.423,19.633,7.997z"></path>
                            </svg>
                            <span className="u-screen-reader-text">
                              Twitter
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              //   style="fill:rgba(0, 0, 0, 1);transform:;-ms-filter:"
                            >
                              <path d="M11.999,7.377c-2.554,0-4.623,2.07-4.623,4.623c0,2.554,2.069,4.624,4.623,4.624c2.552,0,4.623-2.07,4.623-4.624 C16.622,9.447,14.551,7.377,11.999,7.377L11.999,7.377z M11.999,15.004c-1.659,0-3.004-1.345-3.004-3.003 c0-1.659,1.345-3.003,3.004-3.003s3.002,1.344,3.002,3.003C15.001,13.659,13.658,15.004,11.999,15.004L11.999,15.004z"></path>
                              <circle cx="16.806" cy="7.207" r="1.078"></circle>
                              <path d="M20.533,6.111c-0.469-1.209-1.424-2.165-2.633-2.632c-0.699-0.263-1.438-0.404-2.186-0.42 c-0.963-0.042-1.268-0.054-3.71-0.054s-2.755,0-3.71,0.054C7.548,3.074,6.809,3.215,6.11,3.479C4.9,3.946,3.945,4.902,3.477,6.111 c-0.263,0.7-0.404,1.438-0.419,2.186c-0.043,0.962-0.056,1.267-0.056,3.71c0,2.442,0,2.753,0.056,3.71 c0.015,0.748,0.156,1.486,0.419,2.187c0.469,1.208,1.424,2.164,2.634,2.632c0.696,0.272,1.435,0.426,2.185,0.45 c0.963,0.042,1.268,0.055,3.71,0.055s2.755,0,3.71-0.055c0.747-0.015,1.486-0.157,2.186-0.419c1.209-0.469,2.164-1.424,2.633-2.633 c0.263-0.7,0.404-1.438,0.419-2.186c0.043-0.962,0.056-1.267,0.056-3.71s0-2.753-0.056-3.71C20.941,7.57,20.801,6.819,20.533,6.111z M19.315,15.643c-0.007,0.576-0.111,1.147-0.311,1.688c-0.305,0.787-0.926,1.409-1.712,1.711c-0.535,0.199-1.099,0.303-1.67,0.311 c-0.95,0.044-1.218,0.055-3.654,0.055c-2.438,0-2.687,0-3.655-0.055c-0.569-0.007-1.135-0.112-1.669-0.311 c-0.789-0.301-1.414-0.923-1.719-1.711c-0.196-0.534-0.302-1.099-0.311-1.669c-0.043-0.95-0.053-1.218-0.053-3.654 c0-2.437,0-2.686,0.053-3.655c0.007-0.576,0.111-1.146,0.311-1.687c0.305-0.789,0.93-1.41,1.719-1.712 c0.534-0.198,1.1-0.303,1.669-0.311c0.951-0.043,1.218-0.055,3.655-0.055c2.437,0,2.687,0,3.654,0.055 c0.571,0.007,1.135,0.112,1.67,0.311c0.786,0.303,1.407,0.925,1.712,1.712c0.196,0.534,0.302,1.099,0.311,1.669 c0.043,0.951,0.054,1.218,0.054,3.655c0,2.436,0,2.698-0.043,3.654H19.315z"></path>
                            </svg>
                            <span className="u-screen-reader-text">
                              Instagram
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              //   style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"
                            >
                              <path d="M20.66 6.98a9.932 9.932 0 0 0-3.641-3.64C15.486 2.447 13.813 2 12 2s-3.486.447-5.02 1.34c-1.533.893-2.747 2.107-3.64 3.64S2 10.187 2 12s.446 3.487 1.34 5.02a9.924 9.924 0 0 0 3.641 3.64C8.514 21.553 10.187 22 12 22s3.486-.447 5.02-1.34a9.932 9.932 0 0 0 3.641-3.64C21.554 15.487 22 13.813 22 12s-.446-3.487-1.34-5.02zM12 3.66c2 0 3.772.64 5.32 1.919-.92 1.174-2.286 2.14-4.1 2.9-1.002-1.813-2.088-3.327-3.261-4.54A7.715 7.715 0 0 1 12 3.66zM5.51 6.8a8.116 8.116 0 0 1 2.711-2.22c1.212 1.201 2.325 2.7 3.34 4.5-2 .6-4.114.9-6.341.9-.573 0-1.006-.013-1.3-.04A8.549 8.549 0 0 1 5.51 6.8zM3.66 12c0-.054.003-.12.01-.2.007-.08.01-.146.01-.2.254.014.641.02 1.161.02 2.666 0 5.146-.367 7.439-1.1.187.373.381.793.58 1.26-1.32.293-2.674 1.006-4.061 2.14S6.4 16.247 5.76 17.5c-1.4-1.587-2.1-3.42-2.1-5.5zM12 20.34c-1.894 0-3.594-.587-5.101-1.759.601-1.187 1.524-2.322 2.771-3.401 1.246-1.08 2.483-1.753 3.71-2.02a29.441 29.441 0 0 1 1.56 6.62 8.166 8.166 0 0 1-2.94.56zm7.08-3.96a8.351 8.351 0 0 1-2.58 2.621c-.24-2.08-.7-4.107-1.379-6.081.932-.066 1.765-.1 2.5-.1.799 0 1.686.034 2.659.1a8.098 8.098 0 0 1-1.2 3.46zm-1.24-5c-1.16 0-2.233.047-3.22.14a27.053 27.053 0 0 0-.68-1.62c2.066-.906 3.532-2.006 4.399-3.3 1.2 1.414 1.854 3.027 1.96 4.84-.812-.04-1.632-.06-2.459-.06z"></path>
                            </svg>
                            <span className="u-screen-reader-text">
                              Dribbble
                            </span>
                          </a>
                        </li>
                      </ul>{' '}
                      {/*<!-- end s-footer__social -->*/}
                    </div>

                    <div className="contact-block">
                      <h6>Email</h6>
                      <a href="mailto:restnfeel@gamil.com">restnfeel@gmail.com</a>
                    </div>

                    <div className="contact-block">
                      <h6>Phone</h6>
                      <ul className="contact-list">
                        <li>
                          <a href="tel:197-543-2345">+82 10 2920 0664</a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
            {/*<!-- end pagemain -->*/}
          </section>{' '}
          {/*<!-- pagecontent -->*/}
          <section className="s-testimonials">
            <div className="s-testimonials__header row row-x-center text-center">
              <div className="column xl-8 lg-12">
                <p className="text-pretitle">Testimonials</p>
                <h3>Reviews From Real Clients</h3>
              </div>
            </div>{' '}
            {/*<!--end s-testimonials__header -->*/}
            <div className="row s-testimonials__content">
              <div className="column xl-12 testimonials">
                <div className="swiper-container testimonials__slider page-slider">
                  <div className="swiper-wrapper">
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Molestiae incidunt consequatur quis ipsa autem nam sit
                        enim magni. Voluptas tempore rem. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-01.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>John Rockefeller</strong>
                          <span>Standard Oil Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Voluptas tempore rem. Molestiae incidunt consequatur
                        quis ipsa autem nam sit enim magni. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-04.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>Andrew Carnegie</strong>
                          <span>Carnegie Steel Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Nisi dolores quaerat fuga rem nihil nostrum. Molestiae
                        incidunt consequatur quis ipsa autem nam sit enim magni.
                        Voluptas tempore rem. Explicabo a quaerat sint autem
                        dolore ducimus ut consequatur neque. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-06.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>Henry Ford</strong>
                          <span>Ford Motor Co.</span>
                        </cite>
                      </div>
                    </div>
                    <div className="testimonials__slide swiper-slide">
                      <p>
                        Molestiae incidunt consequatur quis ipsa autem nam sit
                        enim magni. Voluptas tempore rem. Explicabo a quaerat
                        sint autem dolore ducimus ut consequatur neque. Nisi
                        dolores quaerat fuga rem nihil nostrum. Laudantium quia
                        consequatur molestias.
                      </p>
                      <div className="testimonials__author">
                        <img
                          src="images/avatars/user-02.jpg"
                          alt="Author image"
                          className="testimonials__avatar"
                        />
                        <cite className="testimonials__cite">
                          <strong>John Morgan</strong>
                          <span>JP Morgan & Co.</span>
                        </cite>
                      </div>
                    </div>
                  </div>{' '}
                  {/*<!-- end swiper-wrapper -->*/}
                  <div className="swiper-pagination"></div>
                </div>{' '}
                {/*<!--end testimonials__slider -->*/}
              </div>{' '}
              {/*<!-- testimonials -->*/}
            </div>{' '}
            {/*<!--end s-testimonials__content -->*/}
          </section>{' '}
          {/*<!-- end s-testimonials -->*/}
        </section>{' '}
        {/*<!-- s-content-->*/}
        {/*<!-- # footer 
        ================================================== -->*/}
        <Footer />
        {/*<!-- end s-footer -->*/}
      </div>
    </>
  );
}

export default Contact;
