import { createSlice } from '@reduxjs/toolkit';
import { getProjects } from '../../apis/member/member_api';

export const member = createSlice({
  name: 'member',
  project: {},
  initialState: {
    project: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.project = payload;
      })
      .addCase(getProjects.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
      });
  },
});

// export const { getProjects } = member.actions;

export default member.reducer;
