import { useCallback, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Parse from 'parse/dist/parse.min.js';
import qs from 'qs';
import Axios from 'axios';



function OauthService () {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();


  const navigate = useNavigate();
  const [searchParams] = useSearchParams();




  const parseLogIn = useCallback( async  (username) =>{
    // Note that these values come from state variables that we've declared before


    console.log("[usernameValue] ",  username);
    try {
      const loggedInUser = await Parse.User.logIn(`${username}`, `${username}`);
      // logIn returns the corresponding ParseUser object
      console.log(
          `Success! User ${loggedInUser.get(
              'username'
          )} has successfully signed in!`
      );
      // To verify that this is in fact the current user, `current` can be used
      // const currentUser = await Parse.User.current();
      // console.log(loggedInUser === currentUser);
      // Clear input fields
      navigate('/member_home');
      return true;
    } catch (error) {
      // Error can be caused by wrong parameters or lack of Internet connection
      console.error(`Error! ${error.message}`);
      return false;
    }
  },[navigate]);

  const oauthProc = useCallback(async ()=>{
    const code = searchParams.get('code');
    // console.log('[check this]',searchParams, code);
    if(code!=='' || code!==null) {

      const client_id = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`.trim();
      const environment = process.env.NODE_ENV;
      let redirect_uri = 'http://localhost:3000/oauth';
      if(environment==='development') {
        redirect_uri = 'http://localhost:3000/oauth';
      }else{
        redirect_uri ='https://handmadeapp.co.kr/oauth'
      }

      const data = {
        'grant_type':'authorization_code', 
        'client_id': client_id, 
        'redirect_uri' : redirect_uri,
        'code' : code, 
      };
      const payload = qs.stringify(data);

      //   console.log(payload);

      const res = await Axios.post('https://kauth.kakao.com/oauth/token', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        cancelToken: source.token
      });

      if(res.status === 200 ){
        const access_token = res.data.access_token;
        const refresh_token = res.data.refresh_token;

        sessionStorage.setItem('access_token', access_token);
        sessionStorage.setItem('refresh_token', refresh_token);

        const meres = await Axios.get('https://kapi.kakao.com/v2/user/me', {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        console.log('[success ]', meres);
        if(meres.status ===200) {
          const uId ='2769502215'; //`${meres.data.id}`;
          await parseLogIn(uId);

        }

      }else {
        source.cancel("operation canceled by logic");
      }

    }
  },[searchParams, source, parseLogIn]);

  useEffect( ()=>{
    async function fetchAct() {
      await oauthProc();
    }

    fetchAct().then(r => console.log(""));
  },[oauthProc]);

  return (
    <>
    </>
  )
}

export default OauthService;